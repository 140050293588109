/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');

* {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
}

body {
  background-color: #F5F5F5;
}

.global-table-dashboard {
  margin: 0vw 0vw 1vw 0vw;

  table {
    border-collapse: separate;
  }

  .p-datatable-thead th:first-child {
    border-top-left-radius: 5px;
    background-color: #707dbf;
  }

  .p-datatable-thead th:last-child {
    border-top-right-radius: 5px;
    background-color: #707dbf;
  }

  table tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
  }

  table tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
  }

  .pi-sort-alt:before {
    content: "\e99e";
    color: white;
   }

   .pi-sort-alt:before:hover {
    content: "\e99e";
    color: black;
   }


  .p-datatable .p-datatable-thead>tr>th {
    text-align: center;
    background-color: #707dbf;
    text-align: center;
    padding: 0.2rem;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 900;
    color: white;
  }

  .p-datatable .p-datatable-tbody>tr>td {
    text-align: center;
    padding: 0.2rem;
    font-weight: 600;

  }

  .p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
    width: 30px;
}
}


.global-table {

  margin: 0vw 0vw 1vw 0vw;

  table {
    border-collapse: separate;
  }

  .p-datatable-thead th:first-child {
    border-top-left-radius: 5px;
    background-color: #707dbf;
  }

  .p-datatable-thead th:last-child {
    border-top-right-radius: 5px;
    background-color: #707dbf;
  }

  table tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
  }

  table tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
  }

  .pi-sort-alt:before {
    content: "\e99e";
    color: white;
   }

   .pi-sort-alt:before:hover {
    content: "\e99e";
    color: black;
   }


  .p-datatable .p-datatable-thead>tr>th {
    text-align: center;
    background-color: #707dbf;
    text-align: center;
    padding: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 900;
    color: white;
  }

  .p-datatable .p-datatable-tbody>tr>td {
    text-align: center;
    padding: 1rem;
    font-weight: 600;

  }

  .p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
    width: 30px;
}

}

.global-label {
  font-size: 23px;
  margin-left: -12px;
  line-height: 26px;
}

.global-label-form {
  label {
    font-size: 16px;
    line-height: 18px;
    color: black;
  }
}

.global-primary-button {
  .p-button {
    color: #ffffff;
    background: #7b52c7;
    padding: 0.75rem 1.25rem;
    font-size: 18px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 10px;
    height: 36px;

    .pi {
      color: white;
    }
  }
}
.global-button-delete .p-button {
  color: #ffffff;
  background: #FF3838;
  padding: 0.75rem 1rem;
  font-size: 18px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 35px;
  border-color: #FF3838;
  height: 2.5rem;
}
.global-button-add {
  .p-button {
    color: #ffffff;
    background: #707dbf;
    padding: 0.75rem 1.25rem;
    font-size: 18px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 20px;
    border-color: #707dbf;
    height: 2.5rem;
    width: 6.5rem;

    .pi {
      color: white;
    }

    .p-button:hover {
      background: #7b52c7;
    }
  }
}

.p-dropdown-display {

    .p-dropdown .p-dropdown-label.p-placeholder {

     margin-top: -0.6rem;
    }
    .p-dropdown {
      background: rgba(217, 217, 217, 0.5);
      width: 240px;
      height: 30px;

      text-align: center;
      margin-bottom: 0px;

    }
    .p-dropdown.p-dropdown-clearable .p-dropdown-label {
      padding-right: 1.75rem;
      margin-top: -0.6rem;
  }

}

.p-dropdown-globalBox {

  .p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    display: inline-flex;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    min-width: 196px;
}

  .p-dropdown .p-dropdown-label.p-placeholder {
    margin-top: -0rem;
}
}

.global-dialog-add {
 .p-dialog .p-dialog-header .p-dialog-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #343a40;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 1.5rem 1.5rem;
  text-align: center;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

}

.global-button-op1 {
  .p-button {
    margin-left: 1px;
    color: #ffffff;
    background: #7288FF;
    padding: 0.75rem 0.5rem;
    font-size: 18px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 35px;
    border-color: #7288FF;
    height: 2.5rem;

    .pi {
      color: white;
    }

    .p-button:hover {
      background: #3f4c8e;
    }
  }
}

.global-button-op2 {
  margin-left: 0.5rem;
  .p-button {
    color: #ffffff;
    background: #FF3838;
    padding: 0.75rem 0.5rem;
    font-size: 18px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 35px;
    border-color: #FF3838;
    height: 2.5rem;

    .pi {
      color: white;
    }

    .p-button:hover {
      background: #3f4c8e;
    }
  }
}

.input-global.p-inputtext {
  max-width: 53vw;
  min-width: 10vw;
}

.global-main-table {
  width: 70%;
  margin-top: 1.5%;
  margin-left: 15%;
  margin-bottom: 20px; 


}
.global-table-dashboard-transactions {
  max-width: 80vw;
  min-width: 380px;
  margin-bottom: 20px; 
}

.global-main-big-table {
  width: 90%;
  margin-top: 1%;
  margin-left: 5%;
  margin-bottom: 20px; 
}

.global-message-error {
  span {
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: small;
    text-align: center;
    display: block;
  }
}

.global-dropdown {
  .p-dropdown {
    background: rgba(217, 217, 217, 0.5);
    border-radius: 10px;
    color: black;
    min-width: 245px;
    text-align: center;

    .p-dropdown-label {
      //background: rgba(217, 217, 217, 0.5);
      border-radius: 10px;
      color: black;
      height: 32px;
      padding: 2% 0px 0% 7%;
    }

    .p-dropdown-trigger {
      //background: rgba(217, 217, 217, 0.5);
      border-radius: 10px;
      color: black;
    }
  }
}
